import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-infinity-fit";
import {Helmet} from "react-helmet";

class InfinityFit extends Component {

    render() {

        return (

            <div id="portfolio-details">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Infinity Fit | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/infinity-fit" />
                    <meta name="description" content="Infinity Fit is an all-in-one fitness app that offers professionally guided tailor-made workout programs, healthy nutrition and meal plans."/>
                </Helmet>

                <ProjectInfo />
            </div>

        );
    }
}

export default InfinityFit;
