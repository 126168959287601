import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-zeljko";
import {Helmet} from "react-helmet";

class Zeljko extends Component {

    render() {

        return (

            <div id="portfolio-details">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Željko Joksimović | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/zeljkojoksimovic" />
                    <meta name="description" content="Željko Joksimović released his album in a unique and trendy way - as a music streaming application, with easy-to-navigate and elegant UI."/>
                </Helmet>

                <ProjectInfo />
            </div>

        );
    }
}

export default Zeljko;
