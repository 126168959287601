import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-diners";
import {Helmet} from "react-helmet";


class Diners extends Component {

    render() {

        return (

            <div id="portfolio-details">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Diners Club International | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/diners" />
                    <meta name="description" content="Diners Club International web app solution is a web application that simplifies access to helpful tools, valuable business trends and insights."/>
                </Helmet>
                <ProjectInfo />
            </div>

        );
    }
}

export default Diners;
