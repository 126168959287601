import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-b-cel";
import {Helmet} from "react-helmet";

class BCell extends Component {

    render() {

        return (

            <div id="portfolio-details">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>B. Cell | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/b-cell" />
                    <meta name="description" content="B. Cell is an interactive eLearning platform which allows students to engage in case studies and discussions on various research topics."/>
                </Helmet>
                <ProjectInfo />
            </div>

        );
    }
}

export default BCell;
