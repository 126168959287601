import React, { Component } from 'react';

class HeroFlyImage extends Component {
    render() {

        const imageClassName = this.props.imageClassName || "rs-parallaxlevel-16";
        const imageId = this.props.imageId || "layers-one";
        const imageDataX = this.props.imageDataX || "['left','left','left','left']";
        const imageDataHoffset = this.props.imageDataHoffset || "['-264','-264','-240','-264']";
        const imageDataY = this.props.imageDataY || "['top','top','top','top']";
        const imageDataVoffset = this.props.imageDataVoffset || "['30','30','50','80']";
        const imageFrames = this.props.imageFrames || '[{"from":"x:left;rZ:-90deg;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]';
        const imageStyleZindex = this.props.imageStyleZindex || "5";

        var imageStyle = {
            zIndex: `${imageStyleZindex}`,
            borderWidth: 0 + "px"
        };

        const imageSrc = this.props.imageSrc || "";
        const imgageAlt = this.props.imgageAlt || "Tele Go Official Site";
        const imageWw = this.props.imageWw || "5";
        const imageHh = this.props.imageHh || "5";
        const imageWidth = this.props.imageWidth || "650";
        const imageHeight = this.props.imageHeight || "500";





        return (

            <div
                className={`tp-caption tp-resizeme ${imageClassName}`}
                id={imageId}
                data-x={imageDataX}
                data-hoffset={imageDataHoffset}
                data-y={imageDataY}
                data-voffset={imageDataVoffset}
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="image"
                data-basealign="slide"
                data-responsive_offset="on"
                data-frames={imageFrames}
                data-textalign="['left','left','left','left']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={imageStyle}
            >
                <img src={imageSrc}
                     alt={imgageAlt}
                     data-ww={imageWw}
                     data-hh={imageHh}
                     width={imageWidth}
                     height={imageHeight}
                     data-no-retina
                />
            </div>
        );
    }
}

export default HeroFlyImage;
