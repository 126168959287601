import React, { Component } from 'react';
import "./info-antena.css";
import Slider from "react-slick";

class ProjectInfo extends Component {


    render() {

        var settings = {
            infinite: true,
            className:"telego-class",
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        };

        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">
                        <div className="responsive-order">
                        <div className="col-md-3 col-sm-3 col-xs-12 text-md-left text-center wow fadeInLeft" data-wow-delay="200ms">
                            <div className="project-info-left">
                                <div className="bottom60">
                                    <img alt="SEO" src={"/images/home/antena-m-logo.png"}/>
                                </div>
                                <div className="heading-title">
                                    <span>Client:</span>
                                    <p>Antena M</p>
                                </div>
                                <div className="heading-title">
                                    <span>Key features:</span>
                                    <p>cross-device platform (web portal, android app, iOS app), attractive and easy-to-use UI, advanced admin panel, advertising module, photo and video galleries, social sharing</p>
                                </div>
                                <div className="heading-title">
                                    <span>Technologies:</span>
                                    <p>PHP, ZF3, PostgreSQL, HTML, CSS, jQuery, Nginx, Android</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                            <div className="heading-title text-left project-info-right">
                                <span>News platform</span>
                                <h2 className="fontregular bottom20 darkcolor">Antena M</h2>
                                <p><b>Antena M</b> is one of the fastest growing news platforms in the region. Available on multiple devices, with attractive and easy-to-use UI that blends form with functionality, it provides all the breaking news and info to keep you updated. The highly customizable UX, integrated advertising module and the CMS enable Antena M platform to handle huge volumes of content and traffic while still providing intuitive navigation and a visual display that appeal to different audiences.</p>

                                <div className="text-center wow fadeInLeft top60 gallery-padding" data-wow-delay="400ms">
                                    <Slider {...settings}  className="row">

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/antena/antenam-1.jpg">
                                                <img src="images/project-gallery/antena/antenam-1.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/antena/antenam-2.jpg">
                                                <img src="images/project-gallery/antena/antenam-2.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/antena/antenam-homepage.jpg">
                                                <img src="images/project-gallery/antena/antenam-homepage.jpg" alt=""/>
                                            </a>
                                        </div>

                                    </Slider>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

        );

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";
    }
}

export default ProjectInfo;
