import React, { Component } from 'react';
import "./technologiesCardStyle.css";
// import TechnologiesCard from "./technologiesCard";

class Technologies extends Component {
    render() {
        // const techBackgroundColor = this.props.techBackgroundColor || "";
        const techSmallTitle = this.props.techSmallTitle || "Development and";
        const techTitle = this.props.techTitle || "Technologies";

        return (
            <section id="technology" className={`single-feature technology padding`}>

                <div className="tech-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 text-center wow fadeInUp" data-wow-delay="200ms">
                                <div className="heading-title heading_space">
                                    <span>{techSmallTitle}</span>
                                    <h2 className="darkcolor bottom60">{techTitle}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div id="services-slider" className="owl-carousel">
                                        <div className="item wow fadeIn" data-wow-delay="300ms">
                                            <div className="service-box">
                                                <span className="bottom10"><i className="fa fa-code"/></span>
                                                <h4 className="bottom20">FrontEnd</h4>
                                                <p>The front end of a website is the part that users interact with. Everything that you see when you’re navigating around the Internet, from fonts and colors to dropdown menus and sliders, is a combo of HTML, CSS, and JavaScript being controlled by your computer’s browser.</p>
                                                <div className="technology-tags">
                                                    <p className="technology-tag">#Angular</p>
                                                    <p className="technology-tag">#ReactJS</p>
                                                    <p className="technology-tag">#JavaScript</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-center wow fadeIn" data-wow-delay="400ms">
                                            <div className="service-box">
                                                <span className="bottom10"><i className="fa fa-laptop"/></span>
                                                <h4 className="bottom20">BackEnd</h4>
                                                <p>So what makes the front end of a website possible? Where is all that data stored? This is where the back end comes in. A back-end developer builds and maintains the technology that powers those components which, together, enable the user-facing side of the website to even exist in the first place.</p>
                                                <div className="technology-tags">
                                                    <p className="technology-tag">#php</p>
                                                    <p className="technology-tag">#java</p>
                                                    <p className="technology-tag">#python</p>
                                                    <p className="technology-tag">#C#</p>
                                                    <p className="technology-tag">#C++</p>
                                                    <p className="technology-tag">#Laravel</p>
                                                    <p className="technology-tag">#Zend</p>
                                                    <p className="technology-tag">#Postgre</p>
                                                    <p className="technology-tag">#MySQL</p>
                                                    <p className="technology-tag">#Android</p>
                                                    <p className="technology-tag">#iOS</p>
                                                    <p className="technology-tag">#Swift</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="service-box wow fadeIn" data-wow-delay="500ms">
                                                <span className="bottom10"><i className="fa fa-mobile-phone"/></span>
                                                <h4 className="bottom20">Mobile</h4>
                                                <p>One of the most intriguing areas of software development has always been mobile development. Why? Because it presents a pretty unique opportunity for a one-person development team to build an actual, usable, meaningful app end-to-end in a relatively short period of time.</p>
                                                <div className="technology-tags">
                                                    <p className="technology-tag">#Android</p>
                                                    <p className="technology-tag">#iOS</p>
                                                    <p className="technology-tag">#Swift</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </section>
        );
    }
}

export default Technologies;
