import React, { Component } from 'react';
import "./portfolioStyle.css"

class PortfolioSingleCard extends Component {


    render() {

        const portfolioHref = this.props.portfolioHref || "/portfolio-details";
        const portfolioCardImageSrc = this.props.portfolioCardImageSrc || "mages/theme/gallery-flat6.jpg";
        // const portfolioCardLinkedProject = this.props.portfolioCardLinkedProject || "mages/theme/gallery-flat6.jpg";
        // const portfolioCardIcon = this.props.portfolioCardIcon || "fa-desktop";
        const portfolioCardTitle = this.props.portfolioCardTitle || "Our Project";
        const portfolioCardSubtitle = this.props.portfolioCardSubtitle || "Small Portfolio Detail Here";
        const PortfolioTechnologyTag = this.props.PortfolioTechnologyTag || "web logo";
        // const PortfolioTechnologyDataWow = this.props.PortfolioTechnologyDataWow || "300ms";

        return (


                <div className={`cbp-item ${PortfolioTechnologyTag} telego-portfolio-card`} >
                    <img src={ portfolioCardImageSrc } alt=""/>
                    <a className="overlay center-block whitecolor"  href={ portfolioHref }>
                        {/*<a className={`fa ${ portfolioCardIcon } portfolio-icon`} href={ portfolioHref }></a>*/}
                        <div className="projectName"><h4 className="portfolio-project-title">{ portfolioCardTitle }</h4> {portfolioCardSubtitle} </div>
                    </a>
                </div>

        );
    }
}

export default PortfolioSingleCard;
