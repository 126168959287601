import React, { Component } from 'react';
import "./workProcess.css";

class WorkProcess extends Component {
    render() {

        const processSmallTitle = this.props.processSmallTitle || "Explore";
        const processTitle = this.props.processTitle || "Work";
        const processTitleBoldedPart = this.props.processTitleBoldedPart || "Process";
        // const processText = this.props.processText || "Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit. Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin. Quisque vitae sodales lectus. Nam porttitor justo sed mi finibus, vel tristique risus faucibus. ";

        return (

            <section id="work-process"
                     className="padding gradient_bg_default work-process">
                
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 col-sm-12 text-center">
                            <div className="heading-title whitecolor wow fadeInUp" data-wow-delay="300ms">
                                <span>{processSmallTitle}</span>
                                <h2 className="whitecolor bottom30">{processTitle} <span className="fontregular">{processTitleBoldedPart}</span> </h2>
                                <p>As the first sentence of the Agile Manifesto goes – <br></br> “<i>We are uncovering better ways of developing software by doing it and helping others do it </i>”.  <br></br> To act accordingly, we:</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <ul className="process-wrapp">
                            <li className="whitecolor wow fadeIn" data-wow-delay="300ms">
                                <span className="pro-step bottom20 planning"></span>
                                <p className="fontbold bottom25">Plan</p>
                                <p>Requirement Analysis, Scope documentation, Business Requirement Gathering</p>
                            </li>
                            <li className="whitecolor wow fadeIn" data-wow-delay="400ms">
                                <span className="pro-step bottom20 analyze"></span>
                                <p className="fontbold bottom25">Analyze</p>
                                <p>Feasibility study, Functional specification documentation</p>
                            </li>
                            <li className="whitecolor wow fadeIn" data-wow-delay="500ms">
                                <span className="pro-step bottom20 design"></span>
                                <p className="fontbold bottom25">Design</p>
                                <p>Wireframes and Prototypes, UX design, Unit testing</p>
                            </li>
                            <li className="whitecolor wow fadeIn" data-wow-delay="600ms">
                                <span className="pro-step bottom20 development"></span>
                                <p className="fontbold bottom25">Develop</p>
                                <p>Developer coordination, Project management, Integration Testing</p>
                            </li>
                            <li className="whitecolor wow fadeIn" data-wow-delay="700ms">
                                <span className="pro-step bottom20 accept"></span>
                                <p className="fontbold bottom25">Accept</p>
                                <p>Functional Testing, Bug management, Acceptance Testing</p>
                            </li>
                            <li className="whitecolor wow fadeIn" data-wow-delay="700ms">
                                <span className="pro-step bottom20 deploy"></span>
                                <p className="fontbold bottom25">Deploy</p>
                                <p>Coordination, Plan for monitoring and response to application security</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

        );
    }
}

export default WorkProcess;
