import React, { Component } from 'react';
import "./footer.css"

class Footer extends Component {
    render() {

        return (

            <footer id="site-footer" className="padding_half">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 text-center">
                            <img src="/images/tele-go-logo.png" alt="logo" className="footer-logo bottom45" />
                            <ul className="social-icons small bottom25 wow fadeInUp" data-wow-delay="300ms">
                                <li><a href="https://www.facebook.com/TeleGoSolutions" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i> </a> </li>
                                <li><a href="https://www.instagram.com/telego_solutions/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i> </a> </li>
                                <li><a href="https://www.linkedin.com/company/tele-go/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i> </a> </li>
                                {/* <li><a href="/"><i className="fa fa-envelope-o"></i> </a> </li> */}
                            </ul>
                            <p className="copyrights wow fadeInUp" data-wow-delay="350ms"> &copy; {new Date().getFullYear()} Tele Go. Made With Love by Our <a href="/">Creative Team</a></p>
                        </div>
                    </div>
                </div>
            </footer>

        );
    }
}

export default Footer;
