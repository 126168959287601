import React, { Component } from 'react';

import HeroContent from "./heroContent";

class Hero extends Component {
    render() {

        return (

            // Ful Screen hero Banner
            <section className="no-padding wow fadeIn no-transition"
                     id="home">
                <div
                    id="revo_main_wrapper"
                    className="rev_slider_wrapper fullscreen-container rev-slider"
                    data-alias="inspiration-header"
                    data-source="gallery"
                    style={{ background: '#FFFFFF',
                             padding: 0 + "px" }}>

                    {/*start revolution slider 5.4.1 fullscreen mode*/}
                    <div
                        id="rev_single"
                        className="rev_slider fullscreenbanner"
                        style={{ display: 'none' }}
                        data-version="5.4.1"
                        >

                        <ul>

                            <HeroContent

                                imgSrcHeroBackground = {this.props.imgSrcHeroBackground}

                                imgSrcHeroTopLeft = {this.props.imgSrcHeroTopLeft}
                                imgSrcHeroTopRight = {this.props.imgSrcHeroTopRight}
                                imgSrcHeroBottomLeft = {this.props.imgSrcHeroBottomLeft}
                                imgSrcHeroBottomLeftOverlayer1 = {this.props.imgSrcHeroBottomLeftOverlayer1}
                                imgSrcHeroBottomLeftOverlayer2 = {this.props.imgSrcHeroBottomLeftOverlayer2}
                                imgSrcHeroBottomRight = {this.props.imgSrcHeroBottomRight}
                                imgSrcHeroBottomRightOverlayer1 = {this.props.imgSrcHeroBottomRightOverlayer1}

                                textHeadline = {this.props.textHeadline}
                                textHeadlineBolded = {this.props.textHeadlineBolded}
                                textHeadlineSmall = {this.props.textHeadlineSmall}

                            />

                        </ul>
                    </div>
                </div>
            </section>

        );
    }
}

export default Hero;
