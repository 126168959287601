import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-medicard";
import {Helmet} from "react-helmet";

class Medicard extends Component {

    render() {

        return (

            <div id="portfolio-details">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>MediCard | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/medicard" />
                    <meta name="description" content="MediCard is a mobile health app designed to make access to innovative healthcare services and professionals more convenient."/>
                </Helmet>

                <ProjectInfo />
            </div>

        );
    }
}

export default Medicard;
