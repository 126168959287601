import React, { Component } from 'react';
import "./ourTeamCard.css";

class TeamAvatar extends Component {
    render() {

        const ourTeamAvatarPic = this.props.ourTeamAvatarPic || "images/theme/team-7.jpg"
        const ourTeamNameAvatar = this.props.ourTeamNameAvatar || "Tele Go Leaders";
        const ourTeamPositionAvatar = this.props.ourTeamPositionAvatar || "Model, The XeOne Company";
        const ourTeamdataWowDelay = this.props.ourTeamdataWowDelay || "500ms";
        const linkedInProfileUrl = this.props.linkedInProfileUrl;

        function handleOnImageClick(e) {
            if (!linkedInProfileUrl) {
                return;
            }
            const win = window.open(linkedInProfileUrl, '_blank');
            win.focus();
        }

        return (

            <div className="col-lg-3 col-md-6 col-sm-6 col-sx-12 telego-box">
                <div className="team-box no-hover top60 wow fadeIn" data-wow-delay={ourTeamdataWowDelay}>
                    <div className="image">
                        <img src={ourTeamAvatarPic} alt="" />
                    </div>
                    <div className="team-content gradient_bg">
                        <h3>{ourTeamNameAvatar}</h3>
                        <p className="nomargin">{ourTeamPositionAvatar}</p>

                        <i className="fa fa-linkedin team" onClick={(e) => handleOnImageClick()} style={this.props.linkedInProfileUrl ? {} : { display: 'none' }}></i>
                    </div>
                </div>
            </div>

        );
    }
}

export default TeamAvatar;