import React, { Component } from 'react';
import "./ourWorkSlide.css";

class Slide extends Component {
    render() {

        const ourWorkSlideLogo = this.props.ourWorkSlideLogo || "images/theme/logo-1.png"
        const ourWorkSlideHref = this.props.ourWorkSlideHref || "/"

        return (

            <div className="item">
                <a className="logo-item our-work-logo" href={ourWorkSlideHref}>
                    <img alt="Tele Go Partner" src={ourWorkSlideLogo} />
                </a>
            </div>

        );
    }
}

export default Slide;