import React, { Component } from 'react';
import "./info-infinity-fit.css";
import Slider from "react-slick";

class ProjectInfo extends Component {

    render() {

        var settings = {
            infinite: true,
            className:"telego-class",
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        };

        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">
                        <div className="responsive-order">
                            <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">
                                <div className="project-info-left">
                                    <div className="bottom60">
                                        <img alt="SEO" src={"/images/home/infinity-fit-logo.png"}/>
                                    </div>

                                    <div className="heading-title">
                                        <span>Client</span>
                                        <p>Infinity Fit</p>
                                    </div>

                                    <div className="heading-title">
                                        <span>Key featues:</span>
                                        <p>advanced CMS, video and workout mode, personalized programs</p>
                                    </div>

                                    <div className="heading-title">
                                        <span>Technologies:</span>
                                        <p>Laravel, PHP, Angular</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                                <div className="heading-title text-left project-info-right">
                                    <span>Fitness App</span>
                                    <h2 className="fontregular bottom20 darkcolor">Infinity Fit</h2>

                                    <p>Infinity Fit is an <a href="https://infinityfit.io/en/" target="_blank" rel="noopener noreferrer">all-in-one fitness app</a>, that works as a guide towards achieving a healthier lifestyle. All programs are created by professionals, from video-based workout sessions to personalized meal plans.</p>
                                    <p>The app offers tailor-made workout, healthy nutrition and meal plans. By choosing your fitness level, workout environment, dietary preferences and the results you want to achieve, the app will recommend you professionally guided programs that best fit your needs - whether you want to lose weight, get fitter or gain muscle.</p>

                                    <div className="text-center wow fadeInLeft top60 gallery-padding" data-wow-delay="400ms">
                                        <Slider {...settings}  className="row">
                                            <div className="cbp-item telego-class">
                                                <a className="plus" data-fancybox="gallery" href="images/project-gallery/infinity-fit/infinity-fit-1.jpg">
                                                    <img src="images/project-gallery/infinity-fit/infinity-fit-1.jpg" alt=""/>
                                                </a>
                                            </div>

                                            <div className="cbp-item telego-class">
                                                <a className="plus" data-fancybox="gallery" href="images/project-gallery/infinity-fit/infinity-fit-2.jpg">
                                                    <img src="images/project-gallery/infinity-fit/infinity-fit-2.jpg" alt=""/>
                                                </a>
                                            </div>

                                            <div className="cbp-item telego-class">
                                                <a className="plus" data-fancybox="gallery" href="images/project-gallery/infinity-fit/infinity-fit-3.jpg">
                                                    <img src="images/project-gallery/infinity-fit/infinity-fit-3.jpg" alt=""/>
                                                </a>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default ProjectInfo;
