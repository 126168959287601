import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-vendor-lever";
import {Helmet} from "react-helmet";

class VendorLever extends Component {

    render() {

        return (

            <div id="portfolio-details">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Vendor Lever | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/vendor-lever" />
                    <meta name="description" content="Vendor Lever is a plug-and-play software and hardware solution that turns your coffee vending machine into a digital barista."/>
                </Helmet>

                <ProjectInfo />
            </div>

        );
    }
}

export default VendorLever;
