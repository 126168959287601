import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-klikpink";
import {Helmet} from "react-helmet";


class Klikpink extends Component {

    render() {

        return (

            <div id="portfolio-details">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>KlikPink | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/klikpink" />
                    <meta name="description" content="KlikPink is an OTT platform that offers access to your favorite Pink TV content across multiple devices anytime, anywhere."/>
                </Helmet>

                <ProjectInfo />
            </div>

        );
    }
}

export default Klikpink;
