import React, { Component } from 'react';
import "./info-b-cell.css";
import Slider from "react-slick";

class ProjectInfo extends Component {


    render() {

        var settings = {
            infinite: true,
            className:"telego-class",
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        };

        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">
                        <div className="responsive-order">
                            <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">
                            <div className="project-info-left">
                                <div className="bottom60">
                                    <img alt="B-Cell Serbia" src="/images/home/bcell-logo.png"/>
                                </div>
                                <div className="heading-title">
                                    <span>Client:</span>
                                    <p>B. Cell</p>
                                </div>
                                <div className="heading-title">
                                    <span>Key features:</span>
                                    <p>eLearning, interactive education and research data sharing, awareness raising, smart brand design concept and UI</p>
                                </div>
                                <div className="heading-title">
                                    <span>Technologies:</span>
                                    <p>PHP, ZF3, PostgreSQL, HTML, CSS, jQuery, Nginx, Android</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                            <div className="heading-title text-left project-info-right">
                                <span>Interactive cancer research platform</span>
                                <h2 className="fontregular bottom20 darkcolor">B. Cell</h2>
                                <p>Making a difference demands changes in the research paradigm. That’s how B. Cell was founded as a non-profit initiative that provides an online platform where the research community can broadly share, integrate and analyze data from academic and clinical research and trials. The true importance of this platform comes from an ever increasing volume of data and the continuing engagement of specialists focused on advancing future research to improve the lives of cancer patients and their families around the world. Besides offering access to scientific articles and publications, it is also an interactive eLearning platform which allows students to take educational quizzes and engage in case studies and discussions on various research topics. Doing so, they collect “Super Cells” – points they can use for further education. B. Cell’s smart graphic concept was designed to empower the idea behind the initiative and motive users to, as the slogan goes, “learn and evolve”. All these features make B. Cell an extremely valuable concept for raising awareness of the importance of clinical research.</p>

                                <div className="text-center wow fadeInLeft top60 gallery-padding" data-wow-delay="400ms">
                                    <Slider {...settings}  className="row">

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/b-cell/bcell-1.jpg">
                                                <img src="/images/project-gallery/b-cell/bcell-1.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/b-cell/bcell-2.jpg">
                                                <img src="/images/project-gallery/b-cell/bcell-2.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/b-cell/bcell-3.jpg">
                                                <img src="/images/project-gallery/b-cell/bcell-3.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/b-cell/bcell-4.jpg">
                                                <img src="/images/project-gallery/b-cell/bcell-4.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/b-cell/bcell-apk-1.jpg">
                                                <img src="/images/project-gallery/b-cell/bcell-apk-1.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/b-cell/bcell-apk-2.jpg">
                                                <img src="/images/project-gallery/b-cell/bcell-apk-2.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/b-cell/bcell-apk-3.jpg">
                                                <img src="/images/project-gallery/b-cell/bcell-apk-3.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/b-cell/bcell-apk-4.jpg">
                                                <img src="/images/project-gallery/b-cell/bcell-apk-4.jpg" alt=""/>
                                            </a>
                                        </div>
                                    </Slider>
                                </div>

                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

        );

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";
    }
}

export default ProjectInfo;
