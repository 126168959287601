import React, { Component } from 'react';
import "./heroSecond.css";

class HeroSecond extends Component {

    render() {

        const heroSecondHeroSecondImg = this.props.heroSecondHeroSecondImg || "images/home/telego-our-team-background.png";
        const heroSecondHeroTitle = this.props.heroSecondHeroTitle || " ";
        const heroSecondHeroButton = this.props.heroSecondHeroButton || "";

        return (

            <section id="hero-aboutUs" className="page-header parallaxie padding_top center-block wow fadeIn"  style = {{ backgroundImage: "url("+heroSecondHeroSecondImg+")" } }  data-wow-delay="900ms">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-titles text-center">
                                <h2 className="darkcolor font-light">{ heroSecondHeroTitle }</h2>
                                <a href="#0" className="button btn-dark top30 bottom60 pagescroll" style={{display: this.props.heroSecondHeroButton ? 'inline-block' : 'none' }}>{ heroSecondHeroButton }</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default HeroSecond;