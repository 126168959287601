import React, { Component } from 'react';
import "./info-mobypay.css";

class ProjectInfo extends Component {


    render() {

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";

        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">
                        <div className="responsive-order">
                            <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">
                                <div className="project-info-left">
                                    <div className="bottom60">
                                        <img alt="SEO" src={"images/home/mobipay-logo.png"}/>
                                    </div>
                                    <div className="heading-title">
                                        <span>Client</span>
                                        <p>Telekom Srbija</p>
                                    </div>
                                    <div className="heading-title">
                                        <span>Key features:</span>
                                        <p>quick and easy safely guided transactions according to strict security protocols anyplace and anytime, easy-to-use UI, flexibility and scalability, protected user data</p>
                                    </div>
                                    <div className="heading-title">
                                        <span>Technologies:</span>
                                        <p>PHP, ZF3, PostgreSQL, HTML, CSS, jQuery, Nginx</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                                <div className="heading-title text-left project-info-right">
                                    <span>Secure, reliable and convenient carrier billing solution</span>
                                    <h2 className="fontregular bottom20 darkcolor">mobiPay</h2>
                                    <p>Cash is quickly becoming obsolete in today’s digital society and businesses that want to stay relevant for tech-oriented customers invest in mPayment. MobiPay app is a flexible and scalable mobile payment solution that provides secure transactions in just a few clicks. All you need to do is create your mobiPay account and you’re good and ready. From buying clothes to booking hotels, your transactions are safely processed, whether you’re enjoying the sunset by the sea or relaxing at home.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>

        );
    }
}

export default ProjectInfo;
