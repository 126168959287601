import React, { Component } from 'react';

class Loader extends Component {
    render() {
        return (

            <div className="loader">
                <div className="loader-inner" >
                    <div className="loader-blocks" style={{width: 100 + "%", height: 100 + "%"}}>
                        <img src="/images/telego-loader-gif.gif" alt="loader" style={{width: 200 + "px"}}/>
                    </div>
                </div>
            </div>

        );
    }
}

export default Loader;
