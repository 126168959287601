import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-kliksport";
import {Helmet} from "react-helmet";

class Kliksport extends Component {

    render() {

        return (

            <div id="portfolio-details">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>KlikSport | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/kliksport" />
                    <meta name="description" content="KlikSport is a cross-device sport streaming platform that provides a range of features to help you follow your favorite sports."/>
                </Helmet>

                <ProjectInfo />
            </div>

        );
    }
}

export default Kliksport;
