import React, { Component } from 'react';
import "./info-cityplay.css";
import Slider from "react-slick";

class ProjectInfo extends Component {


    render() {

        var settings = {
            infinite: true,
            className:"telego-class",
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        };


        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">

                        <div className="responsive-order">
                            <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">
                            <div className="project-info-left">
                                <div className="bottom60">
                                    <img alt="SEO" src={"/images/home/city-play-logo.png"}/>
                                </div>
                                <div className="heading-title">
                                    <span>Client</span>
                                    <p>CityPlay</p>
                                </div>
                                <div className="heading-title">
                                    <span>Key features:</span>
                                    <p>music streaming, cross-device platform (web portal, android app, iOS app), attractive and easy-to-use UI, secure payment methods</p>
                                </div>

                                <div className="heading-title">
                                    <span>Technologies:</span>
                                    <p>PHP, ZF1, PostgreSQL, HTML, CSS, jQuery, Apache, Android</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                            <div className="heading-title text-left project-info-right">
                                <span>Music streaming platform</span>
                                <h2 className="fontregular bottom20 darkcolor">CityPlay</h2>
                                <p>Enjoy, discover and share the music you love with CityPlay music streaming platform, wherever and whenever you want. CityPlay is the first regional music streaming platform released by a record label and one of the most popular music streaming services in the region. Explore numerous tracks, albums and playlists on your smartphone, tablet or computer and keep all your favorite music in one place - your entire library is with you everywhere.</p>

                                <div className="text-center wow fadeInLeft top60 gallery-padding" data-wow-delay="400ms">
                                    <Slider {...settings}  className="row">

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/city/cityplay-mobile-1.jpg">
                                                <img src="/images/project-gallery/city/cityplay-mobile-1.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/city/cityplay-mobile-2.jpg">
                                                <img src="/images/project-gallery/city/cityplay-mobile-2.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/city/cityplay-mobile-3.jpg">
                                                <img src="/images/project-gallery/city/cityplay-mobile-3.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/city/cityplay-mobile-4.jpg">
                                                <img src="/images/project-gallery/city/cityplay-mobile-4.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="/images/project-gallery/city/cityplay-web.jpg">
                                                <img src="/images/project-gallery/city/cityplay-web.jpg" alt=""/>
                                            </a>
                                        </div>

                                    </Slider>
                                </div>


                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

        );

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";
    }
}

export default ProjectInfo;
