import React, { Component } from 'react';
import "./info-zeljko.css";
import Slider from "react-slick";

class ProjectInfo extends Component {


    render() {

        var settings = {
            infinite: true,
            className:"telego-class",
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        };


        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">

                        <div className="responsive-order">
                            <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">
                                <div className="project-info-left">
                                <div className="bottom60">
                                    <img alt="SEO" src={"images/home/zeljko-logo.png"}/>
                                </div>
                                <div className="heading-title">
                                    <span>Client</span>
                                    <p>Telekom Srbija</p>
                                </div>
                                <div className="heading-title">
                                    <span>Key features:</span>
                                    <p>music streaming, elegant UI, secure payment methods</p>
                                </div>
                                <div className="heading-title">
                                    <span>Technologies:</span>
                                    <p>PHP, ZF3, PostgreSQL, HTML, CSS, jQuery, Apache, Android</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                            <div className="heading-title text-left project-info-right">
                                <span>Music streaming app</span>
                                <h2 className="fontregular bottom20 darkcolor">Željko Joksimović</h2>
                                <p>Željko Joksimović is the most popular pop singer in the region. His latest album was released as a music streaming application, with easy-to-navigate and elegant UI. This project was designed as a unique and trendy way to publish an album in music industry today.</p>

                                <div className="text-center wow fadeInLeft top60 gallery-padding" data-wow-delay="400ms">
                                    <Slider {...settings}  className="row">


                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/zeljko/zeljko-apk-1.jpg">
                                                <img src="images/project-gallery/zeljko/zeljko-apk-1.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/zeljko/zeljko-apk-2.jpg">
                                                <img src="images/project-gallery/zeljko/zeljko-apk-2.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/zeljko/zeljko-apk-3.jpg">
                                                <img src="images/project-gallery/zeljko/zeljko-apk-3.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/zeljko/zeljko-apk-4.jpg">
                                                <img src="images/project-gallery/zeljko/zeljko-apk-4.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/zeljko/zeljko-web.jpg">
                                                <img src="images/project-gallery/zeljko/zeljko-web.jpg" alt=""/>
                                            </a>
                                        </div>

                                    </Slider>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";
    }
}

export default ProjectInfo;
