import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { Redirect } from 'react-router';

// components
import './App.css'
import Loader from "./components/loader/loader";
import Header from "./components/header/header";
import Home from "./components/home";
import AboutUs from "./components/about-us/about-us";
import Portfolio from "./components/portfolio/portfolio";
// import Careers from "./components/careers/careers";
// import PortfolioDetails from "./components/portfolio/portfolio-details/portfolio-details";
import Footer from "./components/footer/footer";
import VendorLever from "./components/portfolio/vendor-lever/vendor-lever";
import InfinityFit from "./components/portfolio/infinity-fit/infinity-fit";
import Kliksport from "./components/portfolio/kliksport/kliksport";
import Zeljko from "./components/portfolio/zeljko/zeljko";
import Antena from "./components/portfolio/antena/antena";
import Cityplay from "./components/portfolio/cityplay/cityplay";
import Medicard from "./components/portfolio/medicard/medicard";
import BCell from "./components/portfolio/b-cell/b-cell";
import Diners from "./components/portfolio/diners/diners";
import Mobypay from "./components/portfolio/mobipay/mobypay";
import Klikpink from "./components/portfolio/klikpink/klikpink";
import NotFound from './components/not-found/NotFound';

class App extends Component {

    render() {

        return (
            <Router>

                <div className="App">
                    <Loader />
                    <Header />
                    <Switch>
                        <Route exact path="/" component={ Home } />
                        <Route exact path="/home" component={ Home } />
                        <Route exact path="/#solutions" component={ Home } />
                        <Route exact path="/#technology" component={ Home } />
                        <Route exact path="/#contact" component={ Home } />
                        <Route path="/about" component={ AboutUs } />
                        <Route path="/portfolio" component={ Portfolio } />
                        {/*<Route path="/careers" component={ Careers } />*/}
                        <Route exact path="/vendor-lever" component={ VendorLever } />
                        <Route exact path="/infinity-fit" component={ InfinityFit } />
                        <Route exact path="/kliksport" component={ Kliksport } />
                        <Route exact path="/zeljkojoksimovic" component={ Zeljko } />
                        <Route exact path="/antena-m" component={ Antena } />
                        <Route exact path="/cityplay" component={ Cityplay } />
                        <Route exact path="/medicard" component={ Medicard } />
                        <Route exact path="/b-cell" component={ BCell } />
                        <Route exact path="/diners" component={ Diners } />
                        <Route exact path="/mobipay" component={ Mobypay } />
                        <Route exact path="/klikpink" component={ Klikpink } />
                        <Route component={ NotFound } />
                    </Switch>
                    <Footer/>
                </div>
            </Router>
        );
    }
}

export default App;
