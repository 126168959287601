import React, { Component } from 'react';
import "./counter.css"

class Counter extends Component {

    render() {

        return (

            <section id="funfacts-four" className="padding_half fact-iconic gradient_bg">
                <div className="container">
                    <div className="row text-center">

                        <div className="col-lg-4 col-sm-4 icon-counters whitecolor margin_half">
                            <div className="img-icon bottom15">
                                <i className="fa fa-users"></i>
                            </div>
                            <div className="counters">
                                <span className="count_nums" data-to="93" data-speed="2500"> </span> <i className="fa fa-percent"></i>
                            </div>
                            <h4>Long-term clients</h4>
                        </div>

                        <div className="col-lg-4 col-sm-4 icon-counters whitecolor margin_half">
                            <div className="img-icon bottom15">
                                <i className="fa fa-desktop"></i>
                            </div>
                            <div className="counters">
                                <span className="count_nums" data-to="27" data-speed="2500"> </span> <i className="fa fa-plus"></i>
                            </div>
                            <h4>Successfully delivered projects</h4>
                        </div>
                        <div className="col-lg-4 col-sm-4 icon-counters whitecolor margin_half">
                            <div className="img-icon bottom15">
                                <i className="fa fa-tasks"></i>
                            </div>
                            <div className="counters">
                                <span className="count_nums" data-to="5" data-speed="2500"> </span> <i className="fa fa-plus"></i>
                            </div>
                            <h4>Cooperation projects</h4>
                        </div>
                    </div>
                </div>
            </section>

    );
    }
}

export default Counter;