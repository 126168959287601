import React, { Component } from 'react';
import "./info-vendor-lever.css";
import Slider from "react-slick";

class ProjectInfo extends Component {

    render() {

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";

        var settings = {
            infinite: true,
            className:"telego-class",
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        };

        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">
                        <div className="responsive-order">
                            <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">
                                <div className="project-info-left">
                                    <div className="bottom60">
                                        <img alt="SEO" src={"/images/home/vendor-lever-logo.png"}/>
                                    </div>

                                    <div className="heading-title">
                                        <span>Client</span>
                                        <p>Tele Go in-house project</p>
                                    </div>

                                    <div className="heading-title">
                                        <span>Key featues:</span>
                                        <p>touchscreen display, custom design, upcycling, engaging UX, vending telemetry, business management system, mobile app</p>
                                    </div>

                                    <div className="heading-title">
                                        <span>Technologies:</span>
                                        <p>C++, Embedded, PHP, Laravel, MySQL, HTML, CSS, React</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                                <div className="heading-title text-left project-info-right">
                                    <span>Smart vending solutions</span>
                                    <h2 className="fontregular bottom20 darkcolor">Vendor Lever</h2>

                                    <p>Today, the demand for an enhanced shopping experience and eco-efficiency is shaping all markets. Consequently, to address these ever-changing market dynamics, vending operators are facing multiple challenges.</p>
                                    <p>Firstly, replacing the existing inventory with the new smart machine models is expensive and leaves vending operators with increased electronic waste. Secondly, without adequate management software maintenance is costly and time-consuming, and finally, their sources of income are limited only on coffee sales.</p>
                                    <p>That is why <a href="https://vendorlever.com/" target="_blank" rel="noopener noreferrer">Vendor Lever</a> has developed a comprehensive hardware and software solution to help vending businesses reach their full potential and stay competitive in the market by playing it smart.</p>

                                    <p><b>This is how it works:</b>
                                        <span className="block portfolio-numbering">–<b>New touchscreen monitor and a modern design door frame</b> are installed to the existing machine mechanism to create a more engaging customer experience and a brand new look.</span>
                                        <span className="block portfolio-numbering">–<b>Core communication box</b> is the brains behind the good looks that connect the existing machine mechanism with the new components and software features.</span>
                                        <span className="block portfolio-numbering">–<b>Vending Telemetry</b> collects machine data remotely and transmits it to management and monitoring systems for further processing.</span>
                                        <span className="block portfolio-numbering">–<b>Business Management System</b> analyzes and displays up-to-date machine health and inventory data to support insight-driven decisions.</span>
                                        <span className="block portfolio-numbering">–<b>Vendor Mobile Apps</b> enable client’s maintenance staff to have real-time machine health and inventory data at the touch of their fingertips anytime, anywhere.</span>
                                    </p>

                                    <p>With Vendor Lever vending businesses will upcycle their existing inventory instead of discarding it, and give it both the looks and the brains of a smart vending machine. This way, they will save money – by upgrading the old machine and optimizing maintenance costs, make money – with additional sources of income based on space for targeted advertising and greater customer experience, and reduce electronic waste.</p>

                                    <div className="text-center wow fadeInLeft top60 gallery-padding" data-wow-delay="400ms">
                                        <Slider {...settings}  className="row">
                                            <div className="cbp-item telego-class">
                                                <a className="plus" data-fancybox="gallery" href="images/project-gallery/vendor-lever/vendor-lever-1.jpg">
                                                    <img src="images/project-gallery/vendor-lever/vendor-lever-1.jpg" alt=""/>
                                                </a>
                                            </div>

                                            <div className="cbp-item telego-class">
                                                <a className="plus" data-fancybox="gallery" href="images/project-gallery/vendor-lever/vendor-lever-2.jpg">
                                                    <img src="images/project-gallery/vendor-lever/vendor-lever-2.jpg" alt=""/>
                                                </a>
                                            </div>

                                            <div className="cbp-item telego-class">
                                                <a className="plus" data-fancybox="gallery" href="images/project-gallery/vendor-lever/vendor-lever-3.jpg">
                                                    <img src="images/project-gallery/vendor-lever/vendor-lever-3.jpg" alt=""/>
                                                </a>
                                            </div>

                                            <div className="cbp-item telego-class">
                                                <a className="plus" data-fancybox="gallery" href="images/project-gallery/vendor-lever/vendor-lever-4.jpg">
                                                    <img src="images/project-gallery/vendor-lever/vendor-lever-4.jpg" alt=""/>
                                                </a>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default ProjectInfo;
