import React, { Component } from 'react';
import "./info-kliksport.css";
import Slider from "react-slick";

class ProjectInfo extends Component {


    render() {

        var settings = {
            infinite: true,
            className:"telego-class",
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        };

        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">
                        <div className="responsive-order">
                        <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">                            <div className="project-info-left">
                                <div className="bottom60">
                                    <img alt="SEO" src={"images/home/kliksport-logo.png"}/>
                                </div>
                                <div className="heading-title">
                                    <span>Client</span>
                                    <p>Telekom Srbija</p>
                                </div>
                                <div className="heading-title">
                                    <span>Key features:</span>
                                    <p>live streaming, cross-device platform (web app, android app, iOS app), live timing and scoring, attractive and easy-to-use UI, secure and convenient multiple payment methods, photo and video galleries</p>
                                </div>
                                <div className="heading-title">
                                    <span>Technologies:</span>
                                    <p>PHP, ZF3, PostgreSQL, HTML, CSS, jQuery, Nginx, Android</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                            <div className="heading-title text-left project-info-right">
                                <span>Live sport streaming platform</span>
                                <h2 className="fontregular bottom20 darkcolor">KlikSport</h2>
                                <p>With <b>KlikSport</b> it’s easier than ever to experience the excitement that sport brings to fans wherever they may be. This easy-to-use live streaming solution provides a spectacular range of features to help you follow your favorite sports:</p>

                                <p>
                                    <span className="block portfolio-numbering">– Enjoy sports events in real-time</span>
                                    <span className="block portfolio-numbering">– Stay updated with the statistics and information about the events using live timing and scoring feature</span>
                                    <span className="block portfolio-numbering">– Get all the latest highlights directly from news, photo and video gallery</span>
                                    <span className="block portfolio-numbering">– Submit secure payments for the tickets</span>
                                    <span className="block portfolio-numbering">– Sport breaking news.</span>
                                </p>

                                <p>Your favourite sports content is just a few clicks away!</p>

                                <div className="text-center wow fadeInLeft top60 gallery-padding" data-wow-delay="400ms">
                                    <Slider {...settings}  className="row">

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-web.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-web.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-web-2.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-web-2.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-web-3.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-web-3.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-web-4.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-web-4.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-web-5.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-web-5.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-web-6.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-web-6.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-web-7.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-web-7.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-mobile.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-mobile.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-mobile-1.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-mobile-1.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-mobile2.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-mobile2.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-mobile-3.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-mobile-3.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-mobile-4.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-mobile-4.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-mobile-5.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-mobile-5.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/kliksport/kliksport-mobile-6.jpg">
                                                <img src="images/project-gallery/kliksport/kliksport-mobile-6.jpg" alt=""/>
                                            </a>
                                        </div>

                                    </Slider>
                                </div>

                                {/*<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>*/}
                                {/*<div className="top50 bottom60 text-center">*/}
                                    {/*<img alt="SEO" src={"/images/about-us/hero-NTP-Park.jpg"} style={{width: 100 + "%"}}/>*/}
                                {/*</div>*/}
                                {/*<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>*/}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

        );

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";
    }
}

export default ProjectInfo;
