import React, { Component } from 'react';
import TeamAvatar from './ourTeamCard';

class OurTeam extends Component {
  render() {
    const ourTeamSmallTitle = this.props.ourTeamSmallTitle || '';
    const ourTeamTitle = this.props.ourTeamTitle || '';

    return (
      <section id="our-team" className="padding bglight">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-sm-12 text-center">
              <div
                className="heading-title wow fadeInUp"
                data-wow-delay="300ms"
              >
                <span>{ourTeamSmallTitle}</span>
                <h2 className="darkcolor bottom20">{ourTeamTitle}</h2>
              </div>
            </div>
          </div>

          <div className="row telego-team">
            <TeamAvatar
              ourTeamAvatarPic={'/images/home/telego-nikola.jpg'}
              ourTeamNameAvatar={'Nikola Grbović'}
              ourTeamPositionAvatar={'Chief Operating Officer'}
              ourTeamdataWowDelay={'400ms'}
            />

            <TeamAvatar
              ourTeamAvatarPic={'/images//home/telego-andriana.jpg'}
              ourTeamNameAvatar={'Andrijana Mihajlović'}
              ourTeamPositionAvatar={'Head Of Business Dev'}
              ourTeamdataWowDelay={'500ms'}
              linkedInProfileUrl={
                'https://www.linkedin.com/in/andrijana-mihajlovic-36452888'
              }
            />

            <TeamAvatar
              ourTeamAvatarPic={'/images//home/telego-mladen.jpg'}
              ourTeamNameAvatar={'Mladen Brndušić'}
              ourTeamPositionAvatar={'Chief Technology Officer'}
              ourTeamdataWowDelay={'600ms'}
              linkedInProfileUrl={'https://www.linkedin.com/in/brndusic/'}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default OurTeam;
