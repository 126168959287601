import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-mobypay";
import {Helmet} from "react-helmet";


class Mobypay extends Component {

    render() {

        return (

            <div id="portfolio-details">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>MobiPay | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/mobipay" />
                    <meta name="description" content="MobiPay app is a flexible and scalable mobile payment solution that provides quick and easy safely guided transactions just a few clicks."/>
                </Helmet>

                <ProjectInfo />
            </div>

        );
    }
}

export default Mobypay;
