import React, { Component } from 'react';
import "../../about-us/hero/heroSecond.css";
import ProjectInfo from "./info-antena"
import {Helmet} from "react-helmet";

class Antena extends Component {

    render() {

        return (

            <div id="portfolio-details">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Antena M | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/antena-m" />
                    <meta name="description" content="Antena M is a cross-device news platform that keeps users updated with the latest news on politics, society, sport, culture, etc."/>
                </Helmet>

                <ProjectInfo />
            </div>

        );
    }
}

export default Antena;
