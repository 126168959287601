import React, { Component } from 'react';
import "./contact.css"
import Config from '../../../config/config.js';


class Contact extends Component {
    render() {
        return (

            <section id="contact" className="padding_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 text-center">
                            <div className="heading-title heading_space wow fadeInUp" data-wow-delay="300ms">
                                <span>Let's Get In Touch</span>
                                <h2 className="darkcolor bottom30">Contact Tele Go</h2>
                                <p>It’s the first step towards digitalizing your idea into reality. <br></br>Always happy to hear from you.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 margin_bottom wow fadeInUp" data-wow-delay="350ms">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 our-address top40">
                                    <h5 className="bottom25">Address</h5>
                                    <p className="bottom15">Veljka Dugosevica 54 , <span className="block">11060 Belgrade Serbia</span> </p>
                                    <a className="pickus" target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/dir//%D0%9D%D0%B0%D1%83%D1%87%D0%BD%D0%BE-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D1%88%D0%BA%D0%B8+%D0%BF%D0%B0%D1%80%D0%BA+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4,+%D0%92%D0%B5%D1%99%D0%BA%D0%B0+%D0%94%D1%83%D0%B3%D0%BE%D1%88%D0%B5%D0%B2%D0%B8%D1%9B%D0%B0+54,+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4+11000,+%D0%A1%D1%80%D0%B1%D0%B8%D1%98%D0%B0/@44.8036092,20.4325979,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x475a7a877ddeb177:0x47c3527d848abba6!2m2!1d20.50281!2d44.8036307" data-text="Get Directions">Get Directions</a>
                                </div>

                                <div className="col-md-6 col-sm-6 our-address top40">
                                    <h5 className="bottom25">Email</h5>
                                    <p className="bottom15">Office: info@telego.rs<br></br>Support: support@telego.com </p>

                                    <a className="pickus" href="mailto:info@telego.rs" target="_top" data-text="Send a Message">Send a Message</a>
                                </div>
                                {/*<div className="col-md-6 col-sm-6 our-address top40">*/}
                                    {/*<h5 className="bottom25">Support Email</h5>*/}
                                    {/*<p className="bottom15">support@telego.com</p>*/}
                                    {/*<a className="pickus" href="mailto:support@telego.rs" target="_top" data-text="Open a Ticket">Open a Ticket</a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 margin_bottom">
                            <form className="getin_form wow fadeInUp" data-wow-delay="400ms" data-url={Config.API_MAIL_URL + Config.API_ACTION_CONTACT} onSubmit={(e) => {this.doSomething(); e.preventDefault();}} action="">
                                <div className="row">

                                    <div id="response" className="footer-msg-response"></div>

                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group bottom35">
                                            <input className="form-control" type="text" placeholder="First Name:" required id="first_name" name="first_name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group bottom35">
                                            <input className="form-control" type="text" placeholder="Last Name:" required id="last_name" name="last_name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group bottom35">
                                            <input className="form-control" type="email" placeholder="Email:" required id="email" name="email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group bottom35">
                                            <input className="form-control" type="tel" placeholder="Phone:" id="phone" name="phone"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group bottom35">
                                            <textarea className="form-control" placeholder="Message" id="message" name="message"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <button type="submit" className="button btnprimary" id="submit_btn">send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            
        );
    }
}

export default Contact;
