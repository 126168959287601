import React, { Component } from 'react';

import "./home.css"
// components
import HeroSite from "./home/hero/heroSite";
import HighQuality from "./home/highQuality/highQuality";
import Technologies from "./home/technologies/technologies";
import WorkProcess from "./home/workProcess/workProcess";
import OurWork from "./home/ourWork/ourWork";
import OurTeam from "./home/ourTeam/ourTeam";
import Contact from "./home/contact/contact";
import Solutions from "./home/solutions/solutions";


class Home extends Component {
    render() {


        return (

            <div>
                <HeroSite
                    imgSrcHeroBackground = {"/images/theme/transparent-bg.png"}

                    imgSrcHeroTopLeft = {"/images/theme/top_left.png"}
                    imgSrcHeroTopRight = {"/images/theme/top_right.png"}
                    imgSrcHeroBottomLeft = {"/images/theme/papers.png"}
                    imgSrcHeroBottomLeftOverlayer1 = {"/images/theme/header_marker.png"}
                    imgSrcHeroBottomLeftOverlayer2 = {"/images/theme/header_edding.png"}
                    imgSrcHeroBottomRight = {"/images/theme/bottom_right.png"}
                    imgSrcHeroBottomRightOverlayer1 = {"/images/theme/bottom_pen.png"}

                    textHeadline = {"We Develop."}
                    textHeadlineBolded = {"You Grow."}
                    textHeadlineSmall = {"Digitalizing your ideas into reality."}
                />
                <HighQuality
                    solutionBackgroundColor = {"bglight"}
                    solutionSmallHeadline = {"Tailor-made software"}
                    solutionMainHeadline = {"Designed to meet your vision"}
                    solutionMainText1 = {"Growing a business takes significant effort. Being innovative is not only vital for competitiveness, but more importantly, it’s a strategy to retain great partners who are motivated by projects that make a difference. That is why at Tele Go we go an extra mile to understand our clients’ needs and create IT solutions that help them meet today’s goals and prepare for tomorrow’s opportunities. "}
                    solutionMainText2 = {"Participating in the process of developing custom software allows businesses to reflect on what’s truly important for their achievements and Tele Go is there to support them every step of the way. We don't just focus on the short-term outcome of our work, but dedicate ourselves to the long term engagement in order to create lasting competitive products and services."}
                    solutionImgSrc = {"/images/home/high-quality-it-solutions.jpg"}
                />
                <Solutions/>
                <Technologies
                    techBackgroundColor = {""}
                    techSmallTitle = {"Development and"}
                    techTitle = {"Technologies"}
                />
                <WorkProcess
                    processSmallTitle = {"Implementing and executing with agility."}
                    processTitle = {"Explore our"}
                    processTitleBoldedPart = {" Work Process"}
                />

                <OurWork
                    ourWorkSmallTitle = {"Explore"}
                    ourWorkTitle = {"Our Work"}
                />
                <OurTeam
                    ourTeamSmallTitle = {"A team above all. Above all a team."}
                    ourTeamTitle = {"Our management"}
                    ourTeamText = {"Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit. Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin. Quisque vitae sodales lectus. Nam porttitor justo sed mi finibus, vel tristique risus faucibus."}
                />

                <Contact/>

            </div>
        );
    }
}

export default Home;