import React, { Component } from 'react';
import Slide from "./ourWorkSlide";

class OurWork extends Component {
    render() {

        const ourWorkSmallTitle = this.props.ourWorkSmallTitle || "Explore";
        const ourWorkTitle = this.props.ourWorkTitle || "Our Work";
        const ourWorkbackround = this.props.ourWorkbackround || "";

        return (

            <section id="our-work"
                     className={`padding single-feature ${ourWorkbackround}`}>

                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 col-sm-12 text-center wow fadeInUp" data-wow-delay="200ms">
                            <div className="heading-title heading_space">
                                <span>{ourWorkSmallTitle}</span>

                                <h2 className="darkcolor bottom30">{ourWorkTitle}</h2>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">
                    <h3 className="invisible">hidden</h3>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 wow wow fadeInUp" data-wow-delay="300ms">
                            <div id="partners-slider" className="owl-carousel heading_space">
                                <Slide
                                    ourWorkSlideLogo = {"/images//home/vendor-lever-logo.png"}
                                    ourWorkSlideHref = {"/vendor-lever"}
                                />
                                <Slide
                                    ourWorkSlideLogo = {"/images//home/infinity-fit-logo.png"}
                                    ourWorkSlideHref = {"/infinity-fit"}
                                />
                                <Slide
                                    ourWorkSlideLogo = {"/images/home/antena-m-logo.png"}
                                    ourWorkSlideHref = {"/antena-m"}
                                />
                                <Slide
                                    ourWorkSlideLogo = {"/images/home/bcell-logo.png"}
                                    ourWorkSlideHref = {"/b-cell"}
                                />
                                <Slide
                                    ourWorkSlideLogo = {"/images/home/kliksport-logo.png"}
                                    ourWorkSlideHref = {"/kliksport"}
                                />
                                <Slide
                                    ourWorkSlideLogo = {"/images/home/diners-logo.png"}
                                    ourWorkSlideHref = {"/diners"}
                                />
                                <Slide
                                    ourWorkSlideLogo = {"/images/home/zeljko-logo.png"}
                                    ourWorkSlideHref = {"/zeljkojoksimovic"}
                                />
                                <Slide
                                    ourWorkSlideLogo = {"/images/home/city-play-logo.png"}
                                    ourWorkSlideHref = {"/cityplay"}
                                />
                                <Slide
                                    ourWorkSlideLogo = {"/images/home/mobipay-logo.png"}
                                    ourWorkSlideHref = {"/mobipay"}
                                />
                                <Slide
                                    ourWorkSlideLogo = {"/images/home/klikpink-logo.png"}
                                    ourWorkSlideHref = {"/klikpink"}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 text-center center-block top40 wow fadeInUp" data-wow-delay="300ms">
                            <a href="/portfolio" className ="button btnprimary">Our Projects</a>
                        </div>
                    </div>
                </div>

            </section>

        );
    }
}

export default OurWork;
