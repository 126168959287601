import React, { Component } from 'react';
import "./info-diners.css";
import Slider from "react-slick";

class ProjectInfo extends Component {


    render() {

        var settings = {
            infinite: true,
            className:"telego-class",
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        };


        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">
                        <div className="responsive-order">
                            <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">
                            <div className="project-info-left">
                                <div className="bottom60">
                                    <img alt="SEO" src={"/images/home/diners-logo.png"}/>
                                </div>
                                <div className="heading-title">
                                    <span>Client</span>
                                    <p>Diners Club International Belgrade</p>
                                </div>
                                <div className="heading-title">
                                    <span>Key features:</span>
                                    <p>customized and elegant UI, optimized CMS, enhanced UX</p>
                                </div>

                                <div className="heading-title">
                                    <span>Technologies:</span>
                                    <p>PHP, ZF3, PostgreSQL, HTML, CSS, jQuery, Nginx, Android</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                            <div className="heading-title text-left project-info-right">
                                <span>Corporate web application</span>
                                <h2 className="fontregular bottom20 darkcolor">Diners Club International</h2>
                                <p>Diners Club International (DCI) is a direct banking and payment services company owned by Discover Financial Services (NYSE: DFS), one of the most recognized brands in US financial services. DCI provides a range of payment options, benefits and exclusive offers—in partnership with leading merchants, restaurants and service-industry brands around the world.</p>
                                <p>DCI web app solution is a comprehensive web application meant to simplify access to helpful tools, valuable business trends and insights. With its optimized and easy-to-manage CMS and elegant UI, it is designed to provide an enhanced user experience for customers and partners on multiple devices.</p>

                                <div className="text-center wow fadeInLeft top60 gallery-padding" data-wow-delay="400ms">
                                    <Slider {...settings}  className="row">

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/diners/diners-web.jpg">
                                                <img src="images/project-gallery/diners/diners-web.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/diners/diners-web-1.jpg">
                                                <img src="images/project-gallery/diners/diners-web-1.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/diners/diners-web-2.jpg">
                                                <img src="images/project-gallery/diners/diners-web-2.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/diners/diners-web-3.jpg">
                                                <img src="images/project-gallery/diners/diners-web-3.jpg" alt=""/>
                                            </a>
                                        </div>

                                        <div className="cbp-item telego-class">
                                            <a className="plus" data-fancybox="gallery" href="images/project-gallery/diners/diners-mobile.jpg">
                                                <img src="images/project-gallery/diners/diners-mobile.jpg" alt=""/>
                                            </a>
                                        </div>

                                    </Slider>
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";
    }
}

export default ProjectInfo;
