import React, { Component } from 'react';
import Slider from "react-slick";
import "./solutions.css"


class Solutions extends Component {
    render() {
        const servicesBackgroundColor = this.props.backgroundColor || "bglight";
        const servicesSmallHeadline = this.props.solutionSmallHeadline || "Let us tell you a story about";
        const sservicesMainHeadline = this.props.solutionMainHeadline || "How our innovations unfold";
        const servicesMainText1 = this.props.solutionMainText1 || "It’s in the way we work with you to assure your success. At Tele Go each solution is carefully conceptualized before being delivered. We apply methodology that can be customized to support projects of various size and scope. This gives businesses the possibility to define product functionality and end-user experience, obtaining greater flexibility and control.";
        const solutions = [];
        solutions[0] = this.props.solution1 || "Web Development";
        solutions[1] = this.props.solution2 || "Mobile App Development";
        solutions[2] = this.props.solution3 || "Billing and Payment";
        solutions[3] = this.props.solution4 || "VOD";
        solutions[4] = this.props.solution5 || "OTT";
        solutions[5] = this.props.solution6 || "IVR";
        solutions[6] = this.props.solution7 || "UX & UI";
        solutions[7] = this.props.solution8 || "QA";
        solutions[8] = this.props.solution9 || "DevOps";
        solutions[9] = this.props.solution10 || "Business Intelligence";


        var settings = {
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            adaptiveHeight: true,
            customPaging: function (i) {
                return (
                    <a href="/">
                        <p className="wow fadeInUp" data-wow-delay="400ms">{solutions[i]}</p><br />
                    </a>
                );
            }
        };

        var billingStyle = {

            left: 7 + "%",
            transform: "scale(1.1)"
        };

        return (
            <section id="solutions" className={`${servicesBackgroundColor} `} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 col-sm-12 text-center wow fadeInUp" data-wow-delay="300ms">
                            <div className="heading-title slider">
                                <span>{servicesSmallHeadline}</span>

                                <h2 className="darkcolor bottom30">{sservicesMainHeadline}</h2>
                                <p>{servicesMainText1}</p>

                            </div>
                        </div>
                    </div>
                    <Slider {...settings}>
                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 wow fadeInUp solution-center-left top60" data-wow-delay="400ms" style={{ paddingTop: 7 + "%" }}>
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/WEB.png" style={{ transform: "scale(1)" }} />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 wow fadeInUp top50 solution-center-right" data-wow-delay="400ms" style={{ paddingTop: 7 + "%" }}>
                                    <div className="heading-title text-md-left text-center">
                                        <span>{solutions[0]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">Stand out from the crowd.</h3>
                                        <p>Whether you’re an established enterprise or a startup, effective web solutions play a crucial role in raising your business profile. Appealing to your target market is a complex multistage process. That is why Tele Go offers full cycle web development services to help you stay at the forefront. From strategizing to launching and maintenance you will be assigned a team of all the necessary specialists to deliver your project smoothly and efficiently.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 bottom60 solution-center-left top50">
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/Mobile.jpg" style={{ transform: "scale(0.7)" }} />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 wow fadeInUp solution-center-right">
                                    <div className="heading-title text-md-left text-center">
                                        <span>{solutions[1]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">Your brand at their fingertips.</h3>
                                        <p>The world has gone mobile. As a result, mobile apps have become inevitable tools for your brand awareness. Our mobile app solutions for multiple OS platforms are designed with primary focus on usability and flexible accessibility, but also enhanced user experience to make them recognizable and attractive for end-users. We know it’s an ever-changing industry, so by ensuring scalability of our system we prevent your app from falling apart when you decide to add a new feature or two, allowing your business to grow on the go.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 top40 solution-center-left" style={billingStyle}>
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/Billing.png" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 top40 solution-center-right" style={{ paddingTop: 10 + "%" }}>
                                    <div className="heading-title text-md-left text-center ">
                                        <span>{solutions[2]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">Verified by security, reliability and ease of use.</h3>
                                        <p>How to choose the online payment solution that will better suit your e-commerce and please your customers? We’ll try to explain why Tele Go tool should be your choice by answering three crucial questions:</p>

                                        <p><b>Is it secure? </b> Cybersecurity is the most important feature of electronic commerce. Our solutions meet security protocols to guide transactions safely and handle your customers’ delicate data with care; </p>
                                        <p><b>Is it reliable?</b> Reliability is the precondition for trust. Processing payment is not the only thing a quality solution should provide. Think about how convenient it would be to have access to a great tech support whenever you need it. As your partner, we don’t just take care of technical aspects of e-commerce projects, we also deal with complementary tasks from customers’ management to financial consulting for your online business;</p>
                                        <p><b>Is it easy to use?</b> A solution should be compatible with your brand. We design user-friendly interface to be both pleasing to the eye and improve an overall end-user experience. Make things easier to manage by choosing everything you need bundled together into all-in-one solution.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 top40 solution-center-left" style={{ paddingTop: 10 + "%" }}>
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/VOD.png" style={{ transform: "scale(1.1)" }} />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 top40 solution-center-right" style={{ paddingTop: 10 + "%" }}>
                                    <div className="heading-title text-md-left text-center">
                                        <span>{solutions[3]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">Crystal clear choice.</h3>
                                        <p>Video on demand platforms have created a silent revolution on video delivery market. The world has gone digital and thanks to advancement in technology businesses have shifted from traditional media to on-demand services with huge profit potential. If you’re aim is to provide your customers top-quality viewer experience, we’re here to help you build customizable VOD platform featured with:</p>
                                        <ul>
                                            <li>high-end functionalities</li>
                                            <li>multiple device compatibility</li>
                                            <li>hosting that assures faster and smoother video transcoding</li>
                                            <li>flexible scalability to handle any kind of traffic fluctuations</li>
                                            <li>video content management</li>
                                            <li>multiple monetization options to enlarge revenue margin</li>
                                            <li>analytics to get all insights about how each video is performing at the user’s end</li>
                                            <li>security system to defend your VOD service from unauthorised access and exploitation</li>
                                        </ul>
                                        <p>Together we can incorporate a video on demand model for your business that doesn’t just “check the box,” but delivers exceptional content and attractive user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 top60 solution-center-left">
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/OTT.png" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 solution-center-right" style={{ marginTop: 10 + "%" }}>
                                    <div className="heading-title text-md-left text-center">
                                        <span>{solutions[4]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">TV at hand.</h3>
                                        <p>If you’re a media company, regardless of size, seeking to stay up-to-date in the business and address the growing demand of TV anywhere at any time, we can help you develop, execute and manage an over-the-top strategy.</p>
                                        <p>Our OTT solution consists of:</p>
                                        <ul>
                                            <li><b>TV app</b> that is easy-to-use on the surface, but sophisticatedly designed underneath to deliver a flawless user experience to viewers on every platform</li>
                                            <li><b>media server</b> to store, prepare, and deliver high-quality video at scale</li>
                                            <li><b>content management system</b> to manage all information related to the content library from transcoding to metadata</li>
                                            <li><b>advertising servers</b></li>
                                            <li><b>subscription management systems and advanced selling options</b></li>
                                        </ul>
                                        <p>At Tele Go we know pulling together all these necessary pieces required to deliver a top quality experience to consumers is a not an easy task, so our technology grows with you in order to create and maintain a service which not only attracts, but also retains viewers.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 solution-center-left top60">
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/IVR.png" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 solution-center-right" style={{ paddingTop: 7 + "%" }}>
                                    <div className="heading-title text-md-left text-center">
                                        <span>{solutions[5]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">Benefit through automation.</h3>
                                        <p>Interactive Voice Response technology can benefit both large and small enterprises, saving time and money while generating greater customer satisfaction. Our IVR solutions include telephony equipment, software applications, database and supporting infrastructure to provide your business with a customizable automated tool that is easy to use and reliable within various industries. It’s a simple and effective strategy that will significantly reduce costs and increase your company’s efficiency.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 solution-center-left top60">
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/ux-ui.png" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 solution-center-right" style={{ marginTop: 10 + "%" }}>
                                    <div className="heading-title text-md-left text-center">
                                        <span>{solutions[6]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">Beautiful. Intuitive. Easy to navigate. </h3>
                                        <p>As the bar for usability continues to get higher, something that seemed like a luxury had become a necessity. Digital presence doesn’t just represent the brand, it is the brand. Your website and your mobile app aren’t only billboards for the real goods. They are the way people understand your brand, and therefore need to be crafted with the user’s experience in mind. User Experience and User Interface determine customer’s mood, their loyalty, and deserve the same attention you would give to your product. At Tele Go, we know nothing useless can be truly beautiful, so our UX and UI solutions will deliver you apps with design that’s got both the looks and the brains to become a valuable brand for your business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 solution-center-left top60">
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/qa.png" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 solution-center-right" style={{ marginTop: 10 + "%" }}>
                                    <div className="heading-title text-md-left text-center">
                                        <span>{solutions[7]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">Keep quality aligned with goals.</h3>
                                        <p>Quality assurance helps businesses meet clients’ demands and expectations. High quality builds trust with your customers, which, in turn, makes you competitive in the market. It saves costs and fixes issues before problems become larger, and it helps to set and maintain quality standards that represent your brand. A software quality is defined based on the study of its external and internal features. The external quality refers to software performance in real time scenario in operational mode. The internal quality, on the other hand, focuses on the quality of the code written and its functional logic. Our QA solutions apply two important approaches to determine and upgrade both external and internal quality of the software:</p>
                                        <p><b>Defect Management Approach</b> helps to avoid any aspect of design and development that is not in line with the client’s requirements. Defects are categorized and taken care of based on their severity;</p>
                                        <p><b>Quality Attributes Approach</b> focuses on software functionality, security and compliance, usability, stability and maintainability.</p>
                                        <p>This is achieved through process definition, implementation, auditing and training integrated in our services from project management to software design to help you deliver a flawless product.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 solution-center-left top60">
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/devops.png" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 solution-center-right" style={{ paddingTop: 13 + "%" }}>
                                    <div className="heading-title text-md-left text-center">
                                        <span>{solutions[8]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">Collaborative mindset.</h3>
                                        <p>Keeping up with the constant demand for sophisticated software in an increasingly service-oriented world exposed the need for a more holistic approach to software development life cycle. DevOps, regarded as collaborative organizational culture, puts a focus on creating a fast and stable work flow. But, instead of trying to define DevOps, we are going to describe the foundational concepts it is based on and how our solutions reflect those concepts. They all incorporate iteration and continuous feedback to successively refine and deliver projects through all process stages, while empowering people to collaborate and make decisions together quickly and effectively. Main goals of DevOps are to:</p>
                                        <ul>
                                            <li>improve deployment frequency</li>
                                            <li>achieve faster time to market</li>
                                            <li>be efficiently responsive to business needs</li>
                                            <li>lower failure rate of new releases</li>
                                            <li>shorten lead time between fixes</li>
                                            <li>improve time to recovery</li>
                                        </ul>
                                        <p>We encourge an IT mindset that promotes communication, collaboration, integration and automation among software developers and IT operations in order to improve speed and quality of delivering software. </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="solution-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 solution-center-left top20">
                                    <div className="image hover-effect">
                                        <img alt="video img" src="/images/home/bi.png" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 solution-center-right" style={{ paddingTop: 13 + "%" }}>
                                    <div className="heading-title text-md-left text-center">
                                        <span>{solutions[9]}</span>
                                        <h3 className="fontregular bottom20 darkcolor">Create value from big data.</h3>
                                        <p>Covering a range of technologies, business intelligence refers to solutions that retrieve, analyze, and transform data into meaningful information that helps businesses gain a competitive edge by supporting and improving their decisions with relevant, insightful information. Business intelligence is also meant to empower your people as much as your business. Companies have found that allowing employees to access and track analytical and operational data improves work efficiency and goal reaching by monitoring real-time efforts alongside the business plan in a more transparent work environment. Our BI services, as technology-driven processes, consist of analytical processing, querying and reporting. The benefits you gain include:</p>
                                        <ul>
                                            <li>accelerating and improving decision making</li>
                                            <li>optimizing internal business processes</li>
                                            <li>increasing operational efficiency</li>
                                            <li>driving new revenues</li>
                                            <li>identifying market trends</li>
                                            <li>spotting business problems that need to be addressed</li>
                                            <li>gaining competitive advantages over business rivals</li>
                                        </ul>
                                        <p>With our BI solutions you will be able to handle large amounts of information in order to identify and develop new business opportunities. This will support you in implementing an effective strategy to reach and maintain a competitive market advantage and long-term stability.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Slider>
                </div>
            </section>


        );
    }
}

export default Solutions;