import React, { Component } from 'react';
import "./info-medicard.css";

class ProjectInfo extends Component {


    render() {

        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">

                        <div className="responsive-order">
                            <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">
                                <div className="project-info-left">
                                    <div className="bottom60">
                                        <img alt="SEO" src={"images/tele-go-logo.png"}/>
                                    </div>
                                    <div className="heading-title">
                                        <span>Key features:</span>
                                        <p>quick and easy access to healthcare organization services though advanced and intuitive search engine, secure and convenient online payment methods, health services price comparison and discount coupons, up-to-date clinical guidelines and diagnostic support, continuous tracking and management of health data leading to earlier intervention, improved treatment and medication adherence</p>
                                    </div>
                                    <div className="heading-title">
                                        <span>Technologies:</span>
                                        <p>PHP, Laravel, MySQL, HTML, CSS, jQuery, Nginx, React Native</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                                <div className="heading-title text-left project-info-right">
                                    <span>Discover benefits of mHealth</span>
                                    <h2 className="fontregular bottom20 darkcolor">MediCard</h2>
                                    <p>Mobile technology enables healthcare organizations to extend their services by making them accessible anywhere and anytime. Following new tendencies in digital health, Tele Go developed MediCard, a mobile health app designed to make access to innovative healthcare services and professionals more convenient. Using optimized search engines and secure online payment options, patients can select a healthcare organization and service based on their specific needs, schedule doctor’s appointments, while obtaining price discounts and coupons. </p>
                                    <p>This enables patients to manage their personal information and interact with healthcare staff. It also allows healthcare providers to be up-to-date with the most recent health trends and advances in the field, giving them a better idea of how to proceed with patient care.</p>
                                    <p>Making quality healthcare more accessible with the help of technology doesn’t just improve earlier intervention, but also educates consumers about preventive healthcare services and empowers patients to become more involved in the healthcare processes related to them.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";
    }
}

export default ProjectInfo;
