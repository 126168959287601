import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import "./header.css";


class Header extends Component {
    render() {

        return (

            <header className="site-header">
                <nav className="navbar navbar-expand-lg bg-transparent-light static-nav">
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img src="/images/tele-go-logo.png" alt="logo" />
                        </a>
                        <button className="navbar-toggler navbar-toggler-right collapsed d-none" type="button" data-toggle="collapse" data-target="#xenav">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </button>
                        <div className="collapse navbar-collapse menu-color-style" id="xenav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <a className="nav-link" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href={"/#solutions"}>Solutions</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link pagescroll" href={"/#technology"}>Technologies</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href={"/portfolio"}>Portfolio</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href={"/about"}>About Us</a>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*<a className="nav-link" href={"/careers"}>Careers</a>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href={"/#contact"}>Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/*side menu open button*/}
                    <div className="d-lg-inline-block sidemenu_btn" id="sidemenu_toggle">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </nav>

                {/*side menu*/}
                <div className="side-menu side-menu-color-style">
                    <div className="inner-wrapper">
                        <span className="btn-close" id="btn_sideNavClose"><i></i><i></i></span>
                        <nav className="side-nav">
                            <ul className="navbar-nav w-100">
                                <li className="nav-item active">
                                    <a className="nav-link" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href={"/#solutions"}>Solutions</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href={"/#technology"}>Technologies</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href={"/portfolio"}>Portfolio</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href={"/about"}>About Us</a>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*<a className="nav-link" href={"/careers"}>Careers</a>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" href={"/#contact"}>Contact</a>
                                </li>
                            </ul>
                        </nav>

                        <div className="side-footer w-100">
                            <ul className="social-icons-simple white top40">
                                <li><a href="https://www.facebook.com/TeleGoSolutions" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i> </a> </li>
                                <li><a href="https://www.instagram.com/telego_solutions/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i> </a> </li>
                                <li><a href="https://www.linkedin.com/company/tele-go/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i> </a> </li>
                            </ul>
                            <p className="whitecolor">&copy; 2019 Tele Go. Made With Love by Our Creative Team</p>
                        </div>
                    </div>
                </div>
                <div id="close_side_menu" />
            </header>

        );
    }
}

export default Header;