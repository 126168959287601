import React from 'react';

export default () => {

    return (
        
        <section id="error" className="padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-2"></div>
                    <div className="col-md-6 col-sm-8 text-center">
                        <div className="error wow zoomIn">
                            <h1>404</h1>
                            <h2>404</h2>
                        </div>
                        <p className="heading_space">We are sorry. The page you were looking for no longer exists. Maybe the link below could help.</p>
                        <a href="/" className="button btnprimary wow fadeInLeft">Back to home</a>
                    </div>
                    <div className="col-md-3 col-sm-2"></div>
                </div>
            </div>
        </section>
        
    );
};
