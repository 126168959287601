import React, { Component } from 'react';

// components

import HeroSecond from "./hero/heroSecond";
import OurWork from "../home/ourWork/ourWork";
import OurTeam from "./../home/ourTeam/ourTeam";
import WorkProcess from "../home/workProcess/workProcess";
import OurMission from "../about-us/our-mission/our-mission";
import Counter from "../about-us/counter/counter";
import {Helmet} from "react-helmet";

class AboutUs extends Component {
    render() {


        return (

            <div id="our-work">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>About | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/about" />
                    <meta name="description" content="At Tele Go we are devoted to raising a team of of motivated and inspired people in order to create lasting competitive products and services."/>
                </Helmet>

                <HeroSecond
                    heroSecondHeroSecondImg = {"/images/home/telego-our-team-background.png"}
                    heroSecondHeroTitle = {""}
                    heroSecondHeroButton = {""}
                />
                <OurMission
                    ourMissionText = { "At Tele Go we are devoted to projects that help us grow together with our clients. It’s not just about raising the bar to meet the challenges of today, it’s about raising a team of motivated and inspired people and creating a circle of trust in order to drive our partner’s business towards long-term success and innovative future achievements." }
                    ourMissionTestimonial1 = {"Remember what's driving you forward, and stay focused on that sense of purpose. It's what makes the difference between a job and a career."}
                    ourMissionTestimonial2 = {"We believe in fast decision-making process through flexible organizational structure and simple communication."}
                    ourMissionTestimonial3 = {"Advance of technology is based on efficient solutions that improve your everyday life."}
                />
                <OurWork
                    ourWorkSmallTitle = {"Explore"}
                    ourWorkTitle = {"Our Work"}
                    ourWorkbackround = {"bglight"}
                />

                <WorkProcess
                    processSmallTitle = {"Explore"}
                    processTitle = {"Work"}
                    processTitleBoldedPart = {"Process"}
                    processText = {"Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit. Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin. Quisque vitae sodales lectus. Nam porttitor justo sed mi finibus, vel tristique risus faucibus. "}
                />

                <OurTeam
                    ourTeamSmallTitle = {"A team above all. Above all a team."}
                    ourTeamTitle = {"Our management"}
                />

                <Counter/>

            </div>
        );
    }
}

export default AboutUs;
