import React, { Component } from 'react';
import HeroFlyImage from './heroFlyImage'
import "./heroSite.css";

class HeroContent extends Component {
    render() {

        const imgSrcHeroBackground = this.props.imgSrcHeroBackground || "images/theme/transparent-bg.png";

        const imgSrcHeroTopLeft = this.props.imgSrcHeroTopLeft || "images/theme/top_left.png";
        const imgSrcHeroTopRight = this.props.imgSrcHeroTopRight || "images/theme/top_right.png";
        const imgSrcHeroBottomLeft = this.props.imgSrcHeroBottomLeft || "images/theme/papers.png";
        const imgSrcHeroBottomLeftOverlayer1 = this.props.imgSrcHeroBottomLeftOverlayer1 || "images/theme/header_marker.png";
        const imgSrcHeroBottomLeftOverlayer2 = this.props.imgSrcHeroBottomLeftOverlayer2 || "images/theme/header_edding.png";
        const imgSrcHeroBottomRight = this.props.imgSrcHeroBottomRight || "images/theme/bottom_right.png";
        const imgSrcHeroBottomRightOverlayer1 = this.props.imgSrcHeroBottomRightOverlayer1 || "images/theme/bottom_pen.png";

        const textHeadline = this.props.textHeadline || "";
        const textHeadlineBolded = this.props.textHeadlineBolded || "";
        const textHeadlineSmall = this.props.textHeadlineSmall || "";


        return (

            <li
                data-index="rs-layers"
                data-transition="fade"
                data-slotamount="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="500"
                data-rotate="0"
                data-saveperformance="off"
                data-title="Slide"
                data-param1=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-param10=""
                data-description=""
            >
                {/*main img*/}
                <img
                    src={imgSrcHeroBackground}
                    data-bgcolor="#e0e0e0"
                    alt=""
                    data-bgposition="center center"
                    data-bgfit="cover"
                    data-bgrepeat="no-repeat"
                    data-bgparallax="off"
                    className="rev-slidebg"
                    data-no-retina
                />


                {/*LAYERS*/}

                {/*layer N1*/}
                <HeroFlyImage
                    imageClassName={"rs-parallaxlevel-16 image-show"}
                    imageId={"layers-one"}
                    imageDataX={"['-264','-264','-240','-264']"}
                    imageDataHoffset={"['-264','-264','-240','-264']"}
                    imageDataY={"['top','top','top','top']"}
                    imageDataVoffset={"['30','30','50','80']"}
                    imageFrames={'[{"from":"x:left;rZ:-90deg;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'}
                    imageStyleZindex={"5"}
                    imageSrc = {imgSrcHeroTopLeft}
                    imgageAlt = {"Tele Go Official Site"}
                    imageWw={"['650px','650px','650px','650px']"}
                    imageHh={"['500px','500px','500px','500px']"}
                    imageWidth={"650"}
                    imageHeight={"500"}
                />

                {/*layer N2*/}
                <HeroFlyImage
                    imageClassName={"rs-parallaxlevel-15 image-show"}
                    imageId={"layers-two"}
                    imageDataX={"['right','right','right','right']"}
                    imageDataHoffset={"['-5','-5','-70','-144']"}
                    imageDataY={"['top','top','top','top']"}
                    imageDataVoffset={"['-40','-40','-80','-80']"}
                    imageFrames={'[{"from":"x:right;y:-500px;rZ:90deg;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'}
                    imageStyleZindex={"6"}
                    imageSrc = {imgSrcHeroTopRight}
                    imgageAlt = {"Tele Go Official Site"}
                    imageWw={"['550px','550px','550px','550px']"}
                    imageHh={"['471px','471px','471px','471px']"}
                    imageWidth={"550"}
                    imageHeight={"471"}
                />

                {/*layer N3*/}
                <HeroFlyImage
                    imageClassName={"rs-parallaxlevel-10"}
                    imageId={"layers-three"}
                    imageDataX={"['left','left','left','left']"}
                    imageDataHoffset={"['-392','-392','-540','-638']]"}
                    imageDataY={"['bottom','bottom','bottom','bottom']"}
                    imageDataVoffset={"['-484','-484','-520','-522']"}
                    imageFrames={'[{"from":"x:left;rZ:45deg;","speed":2500,"to":"o:1;","delay":650,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'}
                    imageStyleZindex={"7"}
                    imageSrc = {imgSrcHeroBottomLeft}
                    imgageAlt = {"Tele Go Official Site"}
                    imageWw={"['900px','900px','900px','900px']"}
                    imageHh={"['1000px','1000px','1000px','1000px']"}
                    imageWidth={"900"}
                    imageHeight={"1000"}
                />

                {/*layer N4*/}
                <HeroFlyImage
                    imageClassName={"rs-parallaxlevel-12"}
                    imageId={"layers-four"}
                    imageDataX={"['left','left','left','left']"}
                    imageDataHoffset={"['134','134','-2','-57']"}
                    imageDataY={"['bottom','bottom','bottom','bottom']"}
                    imageDataVoffset={"['67','67','50','6']"}
                    imageFrames={'[{"from":"y:bottom;rZ:90deg;","speed":1500,"to":"o:1;","delay":1050,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'}
                    imageStyleZindex={"8"}
                    imageSrc = {imgSrcHeroBottomLeftOverlayer1}
                    imgageAlt = {"Tele Go Official Site"}
                    imageWw={"['200px','200px','200px','200px']"}
                    imageHh={"['300px','300px','300px','300px']"}
                    imageWidth={"200"}
                    imageHeight={"300"}
                />

                {/*layer N5*/}
                <HeroFlyImage
                    imageClassName={"rs-parallaxlevel-15"}
                    imageId={"layers-five"}
                    imageDataX={"['left','left','left','left']"}
                    imageDataHoffset={"['181','181','30','-39']"}
                    imageDataY={"['bottom','bottom','bottom','bottom']"}
                    imageDataVoffset={"['-170','-170','-190','-212']"}
                    imageFrames={'[{"from":"y:bottom;rZ:-90deg;","speed":1500,"to":"o:1;","delay":1250,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'}
                    imageStyleZindex={"9"}
                    imageSrc = {imgSrcHeroBottomLeftOverlayer2}
                    imgageAlt = {"Tele Go Official Site"}
                    imageWw={"['300px','300px','300px','300px']"}
                    imageHh={"['400px','400px','400px','400px']"}
                    imageWidth={"300"}
                    imageHeight={"400"}
                />

                {/*layer N6*/}
                <HeroFlyImage
                    imageClassName={"rs-parallaxlevel-11"}
                    imageId={"layers-six"}
                    imageDataX={"['right','right','right','right']"}
                    imageDataHoffset={"['-364','-364','-479','-553']"}
                    imageDataY={"['bottom','bottom','bottom','bottom']"}
                    imageDataVoffset={"['-155','-155','-180','-202']"}
                    imageFrames={'[{"from":"x:right;rZ:-65deg;","speed":1500,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'}
                    imageStyleZindex={"12"}
                    imageSrc = {imgSrcHeroBottomRight}
                    imgageAlt = {"Tele Go Official Site"}
                    imageWw={"['730px','730px','730px','730px']"}
                    imageHh={"['520px','520px','520px','520px']"}
                    imageWidth={"730"}
                    imageHeight={"520"}
                />

                {/*layer N7*/}
                <HeroFlyImage
                    imageClassName={"rs-parallaxlevel-15"}
                    imageId={"layers-sev"}
                    imageDataX={"['right','right','right','right']"}
                    imageDataHoffset={"['222','222','105','8']"}
                    imageDataY={"['bottom','bottom','bottom','bottom']"}
                    imageDataVoffset={"['8','8','-17','-10']"}
                    imageFrames={'[{"from":"x:right;rZ:90deg;","speed":1500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'}
                    imageStyleZindex={"13"}
                    imageSrc = {imgSrcHeroBottomRightOverlayer1}
                    imgageAlt = {"Tele Go Official Site"}
                    imageWw={"['300px','300px','300px','300px']"}
                    imageHh={"['500px','500px','500px','500px']"}
                    imageWidth={"300"}
                    imageHeight={"500"}
                />

                {/*TEXT*/}

                <div className="tp-caption tp-resizeme"
                     data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                     data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-70','-70']"
                     data-whitespace="nowrap" data-responsive_offset="on"
                     data-width="['none','none','none','none']" data-type="text"
                     data-textalign="['center','center','center','center']"
                     data-transform_idle="o:1;"
                     data-transform_in="x:-50px;opacity:0;s:2000;e:Power3.easeOut;"
                     data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                     data-start="1000" data-splitin="none" data-splitout="none">
                    <h1 className="text-capitalize font-xlight darkcolor">{textHeadline}</h1>
                    <h4 className="text-capitalize fontbold darkcolor hover-hero-text-style">{textHeadlineBolded}</h4>

                </div>

                {/*<div className="tp-caption tp-resizeme"*/}
                     {/*data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"*/}
                     {/*data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"*/}
                     {/*data-whitespace="nowrap" data-responsive_offset="o"*/}
                     {/*data-width="['none','none','none','none']" data-type="text"*/}
                     {/*data-textalign="['center','center','center','center']"*/}
                     {/*data-transform_idle="o:1;"*/}
                     {/*data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"*/}
                     {/*data-transform_out="s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"*/}
                     {/*data-start="1000" data-splitin="none" data-splitout="none">*/}
                    {/*<h1 className="text-capitalize fontbold darkcolor hover-hero-text-style">{textHeadlineBolded}</h1>*/}
                {/*</div>*/}

                <div className="tp-caption tp-resizeme whitecolor"
                     data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                     data-y="['middle','middle','middle','middle']" data-voffset="['20','20','20','20']"
                     data-whitespace="nowrap" data-responsive_offset="on"
                     data-width="['none','none','none','none']" data-type="text"
                     data-textalign="['center','center','center','center']" data-fontsize="['24','24','20','20']"
                     data-transform_idle="o:1;"
                     data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:2;sY:2;skX:0;skY:0;opacity:0;s:1000;e:Power2.easeOut;"
                     data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                     data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                     data-start="1500" data-splitin="none" data-splitout="none">
                    <h4 className="darkcolor font-light text-center hero-small-text">{textHeadlineSmall}</h4>
                </div>

            </li>

        );
    }
}

export default HeroContent;
