import React, { Component } from 'react';
import PortfolioSingleCard from "./portfolioSingleProjectCard"
import "./portfolioStyle.css"
import {Helmet} from "react-helmet";

class Portfolio extends Component {

    render() {

        const portfolioSmallTitle = this.props.portfolioSmallTitle || "Ideas we digitalized into reality";
        const portfolioTitle = this.props.portfolioTitle || "Our portfolio";
        // const portfolioText = this.props.portfolioText || "";

        return (

            <section id="portfolio-xe" className="padding" >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Portfolio | Tele Go</title>
                    <link rel="canonical" href="http://telego.rs/portfolio" />
                    <meta name="description" content="Tele Go takes part in diverse projects and product development, with focus on web and mobile apps, OTT solutions and smart vending."/>
                </Helmet>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="heading-title wow fadeInUp" data-wow-delay="300ms">
                                <span>{portfolioSmallTitle}</span>
                                <h2 className="darkcolor bottom10">{portfolioTitle}</h2>

                                <div id="flat-filters" className="cbp-l-filters dark top20 bottom60">
                                    <div data-filter="*" className="cbp-filter-item">
                                        <span>All</span>
                                    </div>
                                    <div data-filter=".web" className="cbp-filter-item">
                                        <span>Web app</span>
                                    </div>
                                    <div data-filter=".mobile" className="cbp-filter-item">
                                        <span>Mobile app</span>
                                    </div>
                                    <div data-filter=".vod" className="cbp-filter-item">
                                        <span>VOD/OTT</span>
                                    </div>
                                    <div data-filter=".news" className="cbp-filter-item">
                                        <span>News</span>
                                    </div>
                                    <div data-filter=".elearning" className="cbp-filter-item">
                                        <span class="no-transform">eLearning</span>
                                    </div>
                                    <div data-filter=".smartvending" className="cbp-filter-item">
                                        <span>Smart vending</span>
                                    </div>
                                    <div data-filter=".fitness" className="cbp-filter-item">
                                        <span>Fitness</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="flat-gallery" className="cbp telego-portfolio wow fadeIn" data-wow-delay="800ms">
                        <PortfolioSingleCard
                            portfolioHref = {"/vendor-lever"}
                            portfolioCardImageSrc = {"/images/portfolio/list/vendor-lever-portfolio.png"}
                            portfolioCardTitle = {"Vendor Lever"}
                            portfolioCardSubtitle = {"Smart vending solutions"}
                            PortfolioTechnologyTag = {"smartvending web mobile"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/infinity-fit"}
                            portfolioCardImageSrc = {"/images/portfolio/list/infinity-fit-portfolio.png"}
                            portfolioCardTitle = {"Infinity Fit"}
                            portfolioCardSubtitle = {"Fitness App"}
                            PortfolioTechnologyTag = {"fitness web mobile"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/antena-m"}
                            portfolioCardImageSrc = {"images/portfolio/list/antena-portfolio.png"}
                            portfolioCardTitle = {"Antena M"}
                            portfolioCardSubtitle = {"News platform"}
                            PortfolioTechnologyTag = {"news web mobile"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/b-cell"}
                            portfolioCardImageSrc = {"images/portfolio/list/b-cell-portfolio.jpg"}
                            portfolioCardTitle = {"B-CELL"}
                            portfolioCardSubtitle = {"Interactive cancer research platform"}
                            PortfolioTechnologyTag = {"elearning web mobile"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/kliksport"}
                            portfolioCardImageSrc = {"images/portfolio/list/kliksport-portfolio.jpg"}
                            portfolioCardTitle = {"Klik Sport"}
                            portfolioCardSubtitle = {"Live sport streaming platform"}
                            PortfolioTechnologyTag = {"web mobile vod"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/diners"}
                            portfolioCardImageSrc = {"images/portfolio/list/diners-portfolio.jpg"}
                            portfolioCardTitle = {"Diners"}
                            portfolioCardSubtitle = {"Corporate web application"}
                            PortfolioTechnologyTag = {"web"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/zeljkojoksimovic"}
                            portfolioCardImageSrc = {"images/portfolio/list/zeljko-portfolio.png"}
                            portfolioCardTitle = {"Zeljko Joksimovic"}
                            portfolioCardSubtitle = {"Music streaming app"}
                            PortfolioTechnologyTag = {"vod mobile"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/cityplay"}
                            portfolioCardImageSrc = {"images/portfolio/list/cityplay-portfolio.png"}
                            portfolioCardTitle = {"CityPlay"}
                            portfolioCardSubtitle = {"Music streaming platform"}
                            PortfolioTechnologyTag = {"web vod"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/medicard"}
                            portfolioCardImageSrc = {"images/portfolio/list/medicard-portfolio.jpg"}
                            portfolioCardTitle = {"Medicard"}
                            portfolioCardSubtitle = {"Discover benefits of mHealth"}
                            PortfolioTechnologyTag = {"web mobile"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/mobipay"}
                            portfolioCardImageSrc = {"images/portfolio/list/mobypay-portfolio.png"}
                            portfolioCardTitle = {"mobiPay"}
                            portfolioCardSubtitle = {"Secure, reliable and convenient carrier billing solution"}
                            PortfolioTechnologyTag = {"mobile"}
                        />

                        <PortfolioSingleCard
                            portfolioHref = {"/klikpink"}
                            portfolioCardImageSrc = {"images/portfolio/list/klikpink-portfolio.jpg"}
                            portfolioCardTitle = {"Klik Pink"}
                            portfolioCardSubtitle = {"OTT platform for your favorite Pink TV channels"}
                            PortfolioTechnologyTag = {"web vod"}
                        />
                    </div>
                </div>
            </section>
        );
    }
}

export default Portfolio;
