import React, { Component } from 'react';


class OurMission extends Component {

    render() {

        const ourMissionText = this.props.ourMissionBackground || "At Tele Go we are devoted to projects that help us grow together with our clients. It’s not just about raising the bar to meet the challenges of today, it’s about raising a team of motivated and inspired people and creating a circle of trust in order to drive our partner’s business towards long-term success and innovative future achievements.";
        const ourMissionTestimonial1 = this.props.ourMissionTestimonial1 || "If you are a small business and you are interested in small rebranding then this is a perfect plan for you";
        const ourMissionTestimonial2 = this.props.ourMissionTestimonial2 || "This is a perfect plan for you if you are a small business and you are interested in small rebranding.";
        const ourMissionTestimonial3 = this.props.ourMissionTestimonial3 || "We show you our professional achievements in numbers, which show the acquired skills.";

        return (

            <section id="our-testimonial-two" className="padding testimonial-bg " style ={ { backgroundColor:  '#fffff' } }>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-0 col-sm-8 offset-sm-2 wow fadeInUp">
                            <div className="heading-title text-md-left text-center padding_bottom">
                                <span>Our Mission and</span>
                                <h2 className="bottom20 darkcolor font-light">Our Vission</h2>
                                <p>{ ourMissionText }</p>
                            </div>
                        </div>
                        <div className="col-md-6 offset-md-0 col-sm-12 wow fadeInUp" style={ { paddingLeft: "10%" } }>
                            <div id="testimonial-quote" className="owl-carousel" >
                                <div className="item">
                                    <div className="testimonial-quote darkcolor">
                                        <h3 className="bottom30 font-light">{ ourMissionTestimonial2 }</h3>
                                        <h6>Andrijana Mihajlović</h6>
                                        <small>Head of Business Dev, Tele Go</small>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="testimonial-quote darkcolor">
                                        <h3 className="bottom30 font-light">{ ourMissionTestimonial3 }</h3>
                                        <h6>Nikola Grbović</h6>
                                        <small>COO, Tele Go</small>
                                    </div>
                                </div>
                            </div>
                            <div id="owl-thumbs" className="owl-dots">
                                <div className="owl-dot"><img src="/images/home/telego-andriana.jpg" alt=""/></div>
                                <div className="owl-dot"><img src="/images/home/telego-nikola.jpg" alt=""/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default OurMission;
