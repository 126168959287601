import React, { Component } from 'react';
import "./info-klikpink.css";

class ProjectInfo extends Component {


    render() {

        return (

            <section id="project-info" className={"single-feature"}>
                <div className="container">
                    <div className="row">
                        <div className="responsive-order">
                            <div className="col-md-3 col-sm-3 col-xs-12 text-md-left bottom40 text-center wow fadeInLeft" data-wow-delay="200ms">
                            <div className="project-info-left">
                                <div className="bottom60">
                                    <img alt="SEO" src={"images/home/klikpink-logo.png"}/>
                                </div>
                                <div className="heading-title">
                                    <span>Client</span>
                                    <p>Pink TV</p>
                                </div>
                                <div className="heading-title">
                                    <span>Key features:</span>
                                    <p>live streaming, cross-device platform, attractive and easy-to-navigate UI, secure payment methods</p>
                                </div>
                                <div className="heading-title">
                                    <span>Technologies:</span>
                                    <p>PHP, ZF2, PostgreSQL, HTML, CSS, jQuery, Apache, Android</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-md-9 col-sm-9 col-xs-12 wow fadeInLeft responsive-order-top" data-wow-delay="300ms">
                            <div className="heading-title text-left project-info-right">
                                <span>OTT platform for your favorite Pink TV channels</span>
                                <h2 className="fontregular bottom20 darkcolor">KlikPink</h2>
                                <p>Pink TV is the leading broadcasting company in the Western Balkan region. Continuously upgrading content delivery to their ever-growing audience through innovative technologies, Pink TV embraced the world of OTT - and thus KlikPink was born.</p>

                                <p><b>This OTT platform offers access to your favorite Pink TV content across multiple devices and it features:</b>
                                    <span className="block portfolio-numbering">– Live streaming</span>
                                    <span className="block portfolio-numbering">– Attractive and easy-to-navigate UI</span>
                                    <span className="block portfolio-numbering">– Secure payment methods</span>
                                </p>
                                <p>With KlikPink you can catch up with TV series, movies and shows, whether at home or while commuting, and enjoy Pink TV content anytime, anywhere in just a few clicks.</p>

                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

        );

        // const projectInfoClient = this.props.projectInfoClient || "";
        // const projectInfoDuration = this.props.projectInfoDuration || "";
        // const projectInfoDeliveration = this.props.projectInfoDeliveration || "";
        // const projectInfoTechnologies = this.props.projectInfoTechnologies || "";
    }
}

export default ProjectInfo;
