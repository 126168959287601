import React, { Component } from 'react';
import "./highQuality.css"

class HighQuality extends Component {
    render() {
        const solutionBackgroundColor = this.props.backgroundColor || "";
        const solutionSmallHeadline = this.props.solutionSmallHeadline || "Tele Go is an IT company headquartered in Belgrade that offers";
        const solutionMainHeadline = this.props.solutionMainHeadline || "High quality enterprise solutions";
        const solutionMainText1 = this.props.solutionMainText1 || "Applying technical and technological expertise, as well as thorough methodology, in order to develop leading-edge products and provide first-rate solutions that inspire others has been our lodestar throughout the years. We develop leading-edge technology and offer top-quality IT highQuality that will transcend your business.";
        const solutionMainText2 = this.props.solutionMainText2 || "If you are looking for a first-class long-time strategic partner to grow your business, our company is the perfect choice for you. We boast of having a strong and compact team with a number of years working in the field of technology ready to generate edgy and forward-looking ideas. Tele Go also provides constant improvements and optimization to keep your business on top.";
        const solutionImgSrc = this.props.solutionImgSrc || "/images/theme/split-blog.jpg";

        return (

        <section id="our-solutions" className={`padding single-feature ${solutionBackgroundColor}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-7 text-md-left text-center wow fadeInLeft" data-wow-delay="200ms">
                        <div className="heading-title heading_space">
                            <span>{solutionSmallHeadline}</span>

                            <h2 className="darkcolor bottom30">{solutionMainHeadline}</h2>
                        </div>
                        <p className="bottom35">{solutionMainText1}</p>
                        <p className="bottom35">{solutionMainText2}</p>
                    </div>
                    <div className="col-md-6 col-sm-5 wow fadeInRight high-quality-right" data-wow-delay="300ms">
                        <div className="image top50 high-quality-image"><img alt="SEO" src={solutionImgSrc}/></div>
                    </div>
                </div>
            </div>
        </section>


        );
    }
}

export default HighQuality;
